
import {defineComponent, onMounted, ref, watch} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddStockModal from "@/components/modals/forms/AddStockModal.vue";
import EditStockModal from "@/components/modals/forms/EditStockModal.vue";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import StockFilter from "@/components/dropdown/StockFilter.vue";
import router from "@/router";

interface IStock {
  ID: number;
  title: string;
  address: string;
  type: string;
}

export default defineComponent({
  name: "stocks-listing",
  components: {
    Datatable,
    AddStockModal,
    EditStockModal,
    StockFilter
  },
  setup() {
    const store = useStore();
    const checkedStocks = ref([]);
    const tableHeader = ref([
      /*{
        key: "checkbox",
      },*/
      {
        name: "Title",
        key: "title",
        sortable: true,
      },
      {
        name: "Address",
        key: "address",
        sortable: false,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);
    const tableData = ref<Array<IStock>>([]);
    const initStocks = ref<Array<IStock>>([]);
    const isAdmin = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const getUser = () => {
      store.dispatch(Actions.USER_PROFILE)
          .then(({data}) => {
            isAdmin.value = data.role == "admin"
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const fetchData = (option = 'both') => {
      loading.value = true

      store.dispatch(Actions.STOCKS_LIST, option)
          .then(({data}) => {
            tableData.value = data
            loading.value = false
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    onMounted(() => {
      fetchData()
      getUser()
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Stocks Listing", ["Apps", "Stocks"]);
    });

    watch(() => tableData.value, first => {
      initStocks.value.splice(0, initStocks.value.length, ...first);
    });

    const selectedStock = ref<IStock>()

    const selectStock = item => {
      selectedStock.value = item
    }

    const deleteStock = (id) => {
      store.dispatch(Actions.STOCK_DELETE, id)
          .then(() => {
            for (let i = 0; i < tableData.value.length; i++) {
              if (tableData.value[i].ID === id) {
                tableData.value.splice(i, 1);
              }
            }
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    };

    const deleteFewStocks = () => {
      checkedStocks.value.forEach((item) => {
        deleteStock(item);
      });
      checkedStocks.value.length = 0;
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initStocks.value);
      if (search.value !== "") {
        let results: Array<IStock> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const transactions = id => {
      router.push({name: 'apps-transactions-listing', params: {section: 'stock', id}})
    }

    return {
      tableData,
      tableHeader,
      deleteStock,
      search,
      searchItems,
      checkedStocks,
      deleteFewStocks,
      selectStock,
      selectedStock,
      isAdmin,
      loading,
      fetchData,
      transactions
    };
  },
});

export { IStock };
