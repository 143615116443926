
import { defineComponent, ref } from "vue";

interface Filter {
  type: string;
}

export default defineComponent({
  name: "stock-filter",
  components: {},
  methods: {
    filter(value) {
      this.$emit('filterByType', value)
    }
  },
  setup() {
    const data = ref<Filter>({
      type: "both",
    });

    return {
      data,
    };
  },
});
